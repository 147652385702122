<template>
  <inputControl
    v-bind="propsNoValue"
    inputClass="appearance-none"
  >
    <template>
      <input
        ref="input"
        :type="type"
        :placeholder="labeled && placeholder"
        :id="_id"
        :class="{
          'input-right': right,
          [inputClass]: !!inputClass,
        }"
        :disabled="disabled"
        :inputmode="decimals === 0 ? 'numeric' : 'decimal'"
        :value="inputValue"
        @change="$emit('change', value)"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      >
    </template>
    <template #append>
      <slot name="append">
      </slot>
      <div
        v-if="arrows || arrowsAlt"
        class="input-number-actions"
        :class="{ 'input-number-actions_alt': arrowsAlt }"
      >
        <span
          class="input-number-plus"
          :class="{ 'input-number-plus_alt': arrowsAlt }"
          @click="onPlus"
        ></span>
        <span
          class="input-number-minus"
          :class="{ 'input-number-minus_alt': arrowsAlt }"
          @click="onMinus"
        ></span>
      </div>
    </template>
  </inputControl>
</template>

<script>
import inputControl from '@/components/ui/inputControlUi.vue';
import inputMixin from '@/mixins/ui/inputMixin';

export default {
  mixins: [inputMixin],
  props: {
    value: {
      type: Number,
    },
    clearIfMin: {
      type: Boolean,
      default: false,
    },
    decimals: {
      type: Number,
      default: null,
    },
    arrows: {
      type: Boolean,
      default: false,
    },
    arrowsAlt: Boolean,
  },
  components: {
    inputControl,
  },
  data() {
    return {
      inputValue: null,
      valueSnapshot: null,
    };
  },
  computed: {
    propsNoValue() {
      const props = { ...this.$props };
      delete props.value;
      return props;
    },
  },
  watch: {
    inputValue: {
      handler(val) {
        if (this.$isDev) console.log(`watch.inputValue, val: ${val}`);
        this.$emit('input', val);
      },
      immediate: true,
    },
    value: {
      handler(val) {
        if (this.$isDev) console.log(`watch.value, val: ${val}`);
        if (val === null) return;
        this.inputValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    onPlus() {
      let value = (this.max === undefined || (this.value + this.step <= this.max))
        ? Number(((this.value || 0) + this.step).toFixed(2)) : this.max;
      if (this.min !== undefined && this.value + this.step < this.min) value = this.min;
      if (this.$isDev) console.log('onPlus(), value calculated:');
      if (this.$isDev) console.log(value);
      if (this.$isDev) console.log('onPlus(), call event input');
      this.$emit('input', value);
      if (this.$isDev) console.log('onPlus(), call event value');
      this.$emit('change', value);
    },
    onMinus() {
      const min = this.clearIfMin ? null : this.min;
      let value = (this.min === undefined || this.value >= this.min + this.step)
        ? Number(((this.value || 0) - this.step).toFixed(2)) : min;
      if (this.max !== undefined && this.value - this.step > this.max) value = this.max;
      if (this.$isDev) console.log('onMinus(), value calculated:');
      if (this.$isDev) console.log(value);
      if (this.$isDev) console.log('onMinus(), call event input');
      this.$emit('input', value);
      if (this.$isDev) console.log('onMinus(), call event value');
      this.$emit('change', value);
    },
    onInput(e) {
      if (this.$isDev) console.log('onInput()');
      const val = e.target.value;
      const decimals = this.decimals !== null ? `{0,${this.decimals}}` : '*';
      const r = new RegExp(`-?\\d*[.,]*\\d${decimals}`);
      const dot = this.decimals === 0 ? '' : '.';
      const valTrim = val
        .match(r)[0] /** crop up to dot (i.e. '10.') */
        .replace(/[.,]+/, dot) /** replace many dots, comma if exists to one dot (i.e. '10....' => '10.') */
        .trim();
      /** if input is empty string return undefined */
      const numVal = (valTrim === '' || Number.isNaN(Number(valTrim))) ? null : Number(valTrim);
      /** service multiplier for round number */
      const value = numVal;

      /** if input not closes by dot, value will changed to computed */
      if (this.$refs.input) {
        this.$refs.input.value = valTrim;
      }

      /** emit computed value */
      this.$emit('input', value);
    },
    onBlur() {
      this.$emit('update:focused', false);
    },
    onFocus() {
      this.$emit('update:focused', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-number {
  &-actions {
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 1.9rem;
    height: 1rem;
    transform: translateY(0.2rem);

    &_alt {
      flex-direction: column;
      width: 0.7rem;
    }
  }

  &-plus,
  &-minus {
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0.6rem;

    &::after {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      text-align: center;
      transition: all .3s;
      cursor: pointer;
      user-select: none;
    }

    &:hover {
      background-color: var(--grey-light);
    }

    &_alt {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 0;
    }
  }

  &-plus {
    left: 0;

    &_alt {
      top: 10%;
    }
  }

  &-minus {
    left: 1.2rem;

    &_alt {
      left: 0;
      top: 90%;
    }
  }

  &-plus::after {
    content: "+";
  }

  &-minus::after {
    content: "-";
  }
}
</style>
